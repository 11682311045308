<template>
    <div class="page-content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="
              page-title-box
              d-sm-flex
              align-items-center
              justify-content-between

            ">
                        <h4 class="mb-sm-0">Sertifikat</h4>

                        <div class="page-title-right">
                            <ol class="breadcrumb m-0">
                                <li class="breadcrumb-item active">Daftar Sertifikat</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-header align-items-center d-flex">
                            <h4 class="card-title mb-0 flex-grow-1">Daftar Sertifikat</h4>
                        </div>

                        <div class="card-body">
                            <DataTable :url="dataTableUrl" :columns="columnHeaders" :actions="actions"
                                :reload="reloadDataTable" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import DataTable from "../../components/datatable/DataTable.vue";
import { endpoint, host } from "../../host";
import globalService from '../../services/global-service';

export default {
    components: {
        DataTable,
    },

    created() { },

    data() {
        return {
            reloadDataTable: false,
            dataTableUrl: endpoint.share,
            actions: [
                {
                    icon: "bx bx-show",
                    color: "btn-info",
                    tooltip: "unduh sertifikat",
                    onclick: (data) => {
                        this.downloadCertifcate(data['share_number']);
                    },
                },
            ],
            columnHeaders: [
                {
                    text: "Tanggal",
                    render: (data) => {
                        return globalService.dateFormat2(data.created_at);
                    },
                },
                { text: "Periode", name: "share_period.name" },
                { text: "Nomor Sertifikat", name: "share_number" },
                { text: "Total Slot", name: "slot" },

            ],
        };
    },

    methods: {
        downloadCertifcate(share_number){
            window.open(`${host}/certificate/download/${share_number}`, '_blank');
        }
    }


};
</script>

<style>
</style>